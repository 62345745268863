.contact-box {
    background:$paleGradient;
    margin:0 auto;
    max-width:504px;
    text-align:center;
    padding:48px;
    position:relative;
    z-index:5;
    a {
        color:$darkPurple;
    }
    &:before {
        content:'';
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        background:$paleGradient;
        z-index:-1;
    }
    &:after {
        content:'';
        position:absolute;
        width:100%;
        height:100%;
        background-color:$purple;
        left:-10px;
        bottom:-10px;
        z-index:-2;
    }
}
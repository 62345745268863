.faq-holder {
    margin:96px 0 0px 0;
    .reg-title {
        margin-bottom:48px;
    }
}
.faq {
    margin-bottom:48px;
}
.faq-q, .faq-a {
    font-size: 16px;
    line-height: 26px;
}
.faq-a {
    font-weight:300;
}
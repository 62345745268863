.floaty-box-holder {
    margin:120px auto;
    margin-top:60px;
    &.on-top {
        margin-top:240px;
    }
    @include below($s) {
        margin:60px auto;
        &.on-top {
            margin:100px auto 60px auto;
        }
    }
}

.floaty-box {
    background: $gray;
    box-shadow: $heavierShadow;
    padding:80px 120px;
    float:none;
    margin:0 auto;
    width:80%;
    overflow:hidden;
    display:flex;
    position:relative;
    justify-content: center;
    z-index:3;
    &.floaty-price-box {
        display:block;
        padding-bottom:48px;
    }
    @include below($m) {
        width:100%;
        padding:24px;
        display:block;
    }
}
.floaty-item {
    text-align:center;
    margin-right:24px;
    width:25%;
    position:relative;
    z-index:5;
    display:block;
    text-decoration:none;
    &:last-child {
        margin-right:0;
    }
    img {
        height:51px;
        margin-bottom:24px;
        vertical-align: top;
        transform:scale(1);
        transition: .10s ease-out;
    }
    &:hover {
        .reg-title {
            text-decoration:underline;
        }
        img {
            transform:scale(1.1);
            transition:.30s ease-out;
        }
    }
    @include below($m) {
        width:100%;
        margin:24px 0;
    }
}

.floaty-box-title {
    margin-bottom:24px;
}
.area-floaty-box-holder {
    padding:96px 0;
}
.default-mt {
    margin-top:$defaultMargin;
}
.default-mb {
    margin-bottom:$defaultMargin;
}
.tc {
    text-align:center;
}

.fl {
    float:left;
}

.fr {
    float:right;
    @include below($s) {
        float:none;
    }
}
.no-pad {
    padding:0;
}
.mobile {
    @include above($m) {
        display:none;
    }
}
.desktop {
    @include below($m) {
        display:none;
    }
}
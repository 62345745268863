.price-calculator {
    display:block;
    width:100%;
}
.price-col {
    text-align:center;
    padding:12px 9px;
    border-left:1px solid $lightGray;
    border-right:1px solid $lightGray;
    margin-right:47px;
}

.price-option {
    width:100%;
    text-align:left;
    padding:12px 10px;
    border-bottom:1px solid $lightGray;
    position:relative;
    background:$gray;
    transition:background .12s;
    &:hover {
        background:lighten($lightGray, 2%);
    }
    label {
        font-size:16px;
        line-height:26px;
        color:$darkPurple;
        position:relative;
        z-index:11;
        pointer-events:none;
    }
    input[type=radio], input[type=checkbox] {
        appearance:none;
        position:absolute;
        z-index:15;
        top:0;
        left:-18px;
        right:-18px;
        bottom:0;
        height:100%;
        width:114%;
        cursor:pointer;
        border:0;
        &:checked {
            border-width:2px;
            border-style:solid;
            border-image:$defaultGradient;
            border-image-slice: 1;
            background-color:#FFF;
            z-index:10;
        }
        &:focus {
            outline:none;
        }
    }
}

.price-summary {
    border-top:2px solid;
    border-left:0;
    border-right:0;
    border-bottom:0;
    border-image:$defaultGradient;
    border-image-slice:1;
    margin-top:48px;
    padding-top:24px;
    display:block;
}

.price {
    font-size: 36px;
}
.green-price {
    color:$green;
}
.blue-price {
    color:$blue;
}

.price-disclaimer {
    display:block;
    margin:24px 0;
    background-color:$darkWhite;
    padding:12px;
    p {
        font-weight:300;
        font-size: 12px;
        color: #2E294D;
        letter-spacing: 0;
    }
}
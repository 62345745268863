.big-hero-wrapper {
    height:75vh;
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    padding-top:20%;
    @include below($l) {
        padding-left:24px;
        padding-right:24px;
    }
    @include below($m) {
        height:auto;
    }
}
.hero-image {
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
    img {
        max-width:100%;
    }
    @include below($s) {
        position:relative;
        top:initial;
        transform:translateY(0%);
        img {
            max-width:85%;
        }
    }
}
footer {
    background:$darkPurple;
    padding:84px 15px 120px 15px;
    position:relative;
    z-index:2;
    .reg-copy {
        display:inline-block;
        vertical-align: top;
    }
    @include below($s) {
        padding:48px 24px;
    }
}
.footer-logo {
    display:inline-block;
    width:51px;
    margin-right:24px;
    @include below($s) {
        margin-right:0;
        margin-bottom:24px;
    }
}
.newsletter {
    display:inline-block;
    float:right;
    width:38%;
    position:relative;
    @include below($s) {
        float:none;
        width:100%;
        margin-top:24px;
    }
}
.footer-button {
    position:absolute;
    width:80px;
    right:0;
    top:0;
    bottom:0;
    background:0;
    border:0;
    z-index:3;
    background-image:url('../img/triangle.svg');
    background-repeat:no-repeat;
    background-position:60% center;
}

.shadow {
    position:absolute;
    width:100%;
    height:100%;
    bottom:-5px;
    right:-5px;
    background-image:$defaultGradient;
    z-index:0;
}

.footer-bottom {
    padding-left:78px;
    padding-top:48px;
    @include below($s) {
        padding-left:0;
    }
}
.footer-nav {
    display:inline-block;
    vertical-align:top;
    list-style:none;
    margin-right:48px;
    &:last-child {
        margin-right:0;
    }
    li {
        color:white;
        font-size:12px;
        margin-bottom:6px;
        a {
            color:white;
            text-decoration:none;
            &:hover {
                text-decoration:underline;
            }
        }
        &.first {
            font-size:14px;
            font-weight:600;
        }
    }
    @include below($s) {
        width:49%;
        margin:12px 0;
    }
}
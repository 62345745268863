.button, .gform_button[type=submit] {
    text-align:center;
    display:inline-block;
    padding:7px 9px 9px 9px;
    font-size: 18px;
    letter-spacing: 0.5px;
    position:relative;
    text-decoration:none;
    min-width:215px;
    z-index:5;
    transition:.12s all;
    left:0;
    bottom:0;
    &:after {
        content:'';
        position:absolute;
        width:100%;
        height:100%;
        bottom:-5px;
        left:-5px;
        transition:.12s all;
    }
    &:before {
        content:'';
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
    }
    &:hover {
        left:-5px;
        bottom:-5px;
        &:after {
            bottom:0;
            left:0;
        }
    }
}
.button-white-dark {
    background-color:white;
    color:$darkPurple;
    &:after {
        background-color:$darkPurple;
        z-index:-2;
    }
    &:before {
        background-color:white;
        z-index:-1;
    }
}
.button-dark-gradient, .gform_button[type=submit] {
    background-color:$darkPurple;
    color:white;
    &:after {
        background-image:$defaultGradient;
        z-index:-2;
    }
    &:before {
        background-color:$darkPurple;
        z-index:-1;
    }
}
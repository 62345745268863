.burger-king {
    position:fixed;
    background-image:$paleGradient;
    padding:17px 13px;
    box-shadow:$defaultShadow;
    right:24px;
    top:24px;
    border-radius:100%;
    text-align:center;
    z-index:99;
    img {
        width:80%;
        fill {
            color:#FFF !important;
        }
    }
}

.mobile-menu {
    position:fixed;
    z-index:-5;
    top:12px;
    left:12px;
    right:12px;
    bottom:12px;
    background:white;
    box-shadow:$defaultShadow;
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
    opacity:0;
    pointer-events:none;
    transition:opacity .42s;
    &.visible {
        opacity:1;
        pointer-events:initial;
        z-index:98;
    }
    
}
.mobile-overlay {
    position:fixed;
    z-index:-5;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image:$paleGradient;
    opacity:0;
    pointer-events:none;
    transition:opacity .42s;
    &.visible {
        opacity:1;
        pointer-events:initial;
        z-index:95;
    }
}
.mobile-areas {
    padding:24px;
    .reg-title {
        margin-bottom:24px;
    }
}
.submenu-mobile-item {
    display:block;
    margin:24px 0;
    text-decoration:none;
    img {
        display:inline-block;
        height:30px;
        font-size:0;
    }
    h3 {
        display:inline-block;
        color:$purple;
        vertical-align:top;
        position:relative;
        top:4px;
        margin-left:12px;
    }
}
.other-links {
    background:#FFF;
    padding:12px 24px;
    border-top:1px solid $darkWhite;
}
.other-links-item {
    color:$purple;
    display:block;
    font-size:18px;
    margin:24px 0;
}

.mobile-logo {
    position:absolute;
    top:42px;
    left:24px;
    z-index:97;
}
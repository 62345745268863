.blog-list-content {
    p {
        margin:12px 0;
    }

    ul {
        list-style:none;
        
        li {
            position:relative;
            padding-left:24px;
            &:before {
                content:'';
                width:8px;
                height:8px;
                border-radius:100%;
                background-image:$defaultGradient;
                position:absolute;
                left:0;
                top:50%;
                transform:translateY(-50%);
            }
        }
    }

    a {
        color:$darkPurple;
        text-decoration:underline !important;
    }

    blockquote {
        padding:24px;
        margin:24px auto;
        position:relative;
        background-image:$paleGradient;
        p {
            font-size: 22px;
            color: #2E2A4D;
            letter-spacing: 0.92px;
            line-height: 32px;
            font-weight: 400;
            margin-bottom: 6px;
        }

    }

}
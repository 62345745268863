.area-hero {
    width:100%;
    position:relative;
    height:50vh;
    display:block;
    z-index:1;
    background-size:cover;
    @include below($s) {
        padding:24px;
        height:auto;
    }
    .inner-holder {
        height:100%;
    }
}

.area-hero-content {
    position:absolute;
    top:52%;
    left:0;
    .reg-title {
        max-width:405px;
        color:white;
        display:inline-block;
    }
    @include below($s) {
        position:static;
        top:0;
        left:0;
        padding-top:48px;
    }
}

.area-symbol {
    max-width:80px;
    height:auto;
    margin-bottom:-15px;
    margin-right:24px;
    display:inline-block;
    @include below($s) {
        max-width:60px;
        display:block;
        margin-bottom:12px;
    }
}
.area-steps {
    margin-top:88px;
    img {
        max-width:100%;
    }
    @include below($s) {
        margin-bottom:48px;
    }
}
.steps-holder {
    height:100%;
    transform:translateY(40%);
    @include below($m) {
        transform:translateY(20%);
    }
}
.step {
    margin-bottom:48px;
    position:relative;
    padding-left:72px;
}
.step-number {
    background:$darkPurple;
    color:white;
    font-size:28px;
    font-weight:bold;
    position:absolute;
    left:0;
    padding:12px 0;
    width:48px;
    text-align:center;
    z-index:5;
    div {
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        z-index:-1;
        background:$darkPurple;
    }
    &:after {
        content:'';
        z-index:-2;
        position:absolute;
        width:100%;
        height:100%;
        left:-5px;
        bottom:-5px;
        background:$defaultGradient;
    }
}
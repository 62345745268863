.intro-anim-wrapper {
    position:fixed;
    width:100%;
    height:100%;
    background:white;
    z-index:99;
    transition:.8s opacity;
    &.hidden {
        opacity:0;
        pointer-events:none;
    }
    .intro-anim {
        width:5%;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
    }
}
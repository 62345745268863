.reg-title {
    font-size: 22px;
    color:$darkPurple;
    letter-spacing: 0.92px;
    line-height: 32px;
    font-weight:$regular;
    margin-bottom:6px;
}
.reg-copy, .reg-copy p {
    font-weight:$light;
    font-size: 16px;
    color:$darkPurple;
    letter-spacing: 0;
    line-height: 26px;
    &.white {
        color:white;
    }
}
.small-copy {
    font-weight:$regular;
    font-size:12px;
    color:$darkPurple;
    letter-spacing:0;
    line-height:18px;
}
.main-navigation-wrapper {
    width:$contentWidth;
    max-width:$contentMaxWidth;
    margin:0 auto;
    display:block;
    padding:48px 0;
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    z-index:10;
    opacity:0;
    transition:1.2s opacity;
    @include below($m) {
        display:none;
    }
}

.main-navigation {
    background: #FFFFFF;
    
    box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.05);
    border-radius: 4px;
    float:right;
    
}

.main-navigation-item {
    font-weight:$regular;
    font-size:0;
    color: $darkPurple;
    cursor:pointer;
    text-decoration:none;
    padding:19px 48px 21px 48px;
    display:block;
    float:left;
    border-right:1px solid $lightGray;
    position:relative;
    z-index:2;
    span {
        font-size: 16px;
    }
    &:last-child {
        border-color:transparent;
    }
    &.is-active {
        &:after {
            content:'';
            position:absolute;
            height:4px;
            width:100%;
            left:0;
            bottom:-1px;
            background:linear-gradient(-90deg, #C3E84F 2%, #00CFF0 100%);
            z-index:1;
        }
    }
    &:first-child.is-active:after {
        border-bottom-left-radius:4px;
    }
    &:last-child.is-active:after {
         border-bottom-right-radius:4px;
    }
    @include below($l) {
        padding:19px 38px 21px 38px;
        font-size:14px;
    }
    &:hover {
        text-decoration:underline;
    }
    &.lang {
		vertical-align: top;
		span {
			display: inline-block;
			height: 17px;
			vertical-align: top;
			margin:0 8px;
		}
		a {
            color:$darkPurple;
            text-decoration:none;
			vertical-align: top;

            &:hover {
                text-decoration: underline;
            }

			&.lang-fi, &.lang-sv {
				display: inline-block;
				font-size: 0;
				height: 17px;
				width: 28px;
				outline: 1px solid #eee;
			}

			&.lang-fi {
				background-image: url("../img/flag-fi.png");
			}
			&.lang-sv {
				background-image: url("../img/flag-sv.png");
			}
        }
        &:hover {
            text-decoration:none;

        }
    }
}
.main-navigation-item.has-submenu:hover {
    .submenu {
        opacity:1;
        pointer-events:initial;
        transform:translateX(0%);
        z-index:99;
        &:after {
            transform:translateX(0%);
        }
    }
}
.logotype {
    float:left;
    margin-top:14px;
    padding-left:30px;
}
.logo-symbol {
    margin-right:7px;
    position:relative;
    top:-7px;
}
.anim-wrap {
    display:inline-block;
    text-decoration: none;
    font-size:0;
    width:23px;
    height:25px;
    left:0;
    top:45%;
    position:absolute;
    svg {
        width:100%;
        height:100%;
        position:relative;
        top:-7px;
    }
}
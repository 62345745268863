.submenu {
    position:absolute;
    top:150%;
    left:-100%;
    min-width:552px;
    z-index:19;
    box-shadow: -10px -10px 20px 0 rgba(0,0,0,0.05);
    padding:48px;
    opacity:0;
    pointer-events:none;
    transition:.22s all ease-out;
    transform:translatex(-5%);
    .white-bg {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background:white;
        z-index:-1;
    }
    &:after {
        content:'';
        background:$defaultGradient;
        position:absolute;
        width:100%;
        height:100%;
        bottom:-10px;
        right:-10px;
        z-index:-2;
        transition:.22s all ease-out;
        transform:translatex(10%);
    }
    &:before {
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        top:-100%;
        opacity:0;
    }
    &:hover {
        opacity:1;
        transform:translateX(0%);
        pointer-events:initial;
        
        &:after {
            transform:translateX(0%);
            
        }
    }
}
.submenu-item {
    padding-left:60px;
    text-align:left;
    margin-bottom:24px;
    text-decoration:none;
    img {
        position:absolute;
        left:0;
        top:50%;
        transform:translateY(-50%);
    }
    h3 {
        font-weight:400;
        font-size: 18px;
        color: #2E2A4D;
        letter-spacing: 0;
        margin-bottom:6px;
    }
    p {
        opacity: 0.5;
        font-weight:400;
        font-size: 12px;
        color: #2E2A4D;
        letter-spacing: 0;
    }
    &.nmb {
        margin-bottom:0;
    }
    &:hover {
        h3 {
            text-decoration: underline;
        }
    }
}
.split-content {
    display:flex;
    align-items:center;
    position:relative;
    @include below($s) {
        display:block;
    }
    background-color:$darkWhite;
}
.split-content-article {
    max-width:62%;
    display: block;
    position:relative;
    z-index:1;
    flex-align:center;
    margin:0 auto;
    @include below($s) {
        width:100%;
        max-width:100%;
        padding:48px 24px;
    }
}
.split-content-image {
    position:relative;
    z-index:1;
    img {
        height:100%;
        display:block;
        @include above($xl) {
            width:100%;
            height:auto;
            position: relative;
            right: -4px;
        }
        @include below($s) {
            width:100%;
            height:auto;
        }
    }
    @include below($s) {
        margin:0;
    }
}
.holder.blog-wrap {
    padding-top:240px;
    @include below($m) {
        padding-top:100px;
    }
}
.blog-categories {
    display:inline-block;
    position:relative;
    background-color:white;
    padding:48px 24px;
    box-shadow: $defaultShadow;
    z-index:1;
    &:before {
        content:'';
        background:white;
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        z-index:-1;
    }
    &:after {
        content:'';
        position:absolute;
        left:-10px;
        top:10px;
        width:100%;
        height:100%;
        z-index:-2;
        background:$purple;
    }
    @include below($s) {
        width:100%;
    }
}
.category {
    display:block;
    margin-bottom:12px;
    color:$darkPurple;
    text-decoration:none;
    &.active {
        text-decoration:underline;
        font-weight:600;
    }
    &:hover {
        text-decoration: underline;
    }
    &:last-child {
        margin-bottom:0;
    }
}
.blog-post {
    margin-bottom:84px;
    text-decoration:none;
    a {
        text-decoration:none;
    }
}
.blog-list-image {
    display:block;
    float:none;
    margin-bottom:48px;
    position:relative;
    img {
        width:100%;
        position:relative;
        z-index:3;
    }
    
    &:after {
        content:'';
        background-color:$purple;
        width:100%;
        height:98.5%;
        position:absolute;
        left:-10px;
        top:10px;
    }
}
.blog-list-data {

    text-align:right;
    
}

.post-categories {
    list-style:none;
    a {
        color:$darkPurple;
        text-decoration: none;
    }
}

.blog-list-content {
    @include below($s) {
        padding:0 12px;
    }
}
.share {
    display:inline-block;
    color:$blue !important;
    margin:0px 12px 24px 0px;
    text-decoration: underline;
}
@import 'normalize';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 700px;
$m: 1010px;
$l: 1150px;
$xl: 1410px;

// Default content settings.
$contentWidth: 1440px;
$contentInnerWidth: 937px;
$desktopMaxWidth:1800px;
$contentPadding: 15px;
$contentMaxWidth: 90%;
$defaultMargin: 24px;
$gutter: 24px;
// Font weights.
$light:300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$mont: 'Montserrat', sans-serif;

$defaultShadow: 10px 10px 30px 0 rgba(0,0,0,0.05);
$heavierShadow: 10px 10px 30px 0 rgba(0,0,0,0.10);
// Colors
$defaultGradient: linear-gradient(-90deg, #C3E84F 2%, #00CFF0 100%);
$paleGradient: linear-gradient(-90deg, #E2F5A7 2%, #7FE7F8 100%);
$green: #C3E84F;
$blue: #03CFEE;
$darkPurple: #2E2A4D;
$purple: #595572;
$darkWhite: #F6F6F6;
$lightGray: #F6F4F4;
$gray: #FDFDFD;

//Global styling
@import 'globals/type';
@import 'globals/global';
@import 'globals/grid';
@import 'globals/intro';
@import 'globals/buttons';
@import 'globals/helpers';
@import 'globals/wysiwyg';
//Modules
@import 'modules/main-navigation';
@import 'modules/submenu';
@import 'modules/big-hero';
@import 'modules/floating-rect';
@import 'modules/cta-divider';
@import 'modules/split-content';
@import 'modules/signup-form';
@import 'modules/footer';
@import 'modules/blog';
@import 'modules/floaty-box';
@import 'modules/price-calculator';
@import 'modules/area-hero';
@import 'modules/area-steps';
@import 'modules/contact-box';
@import 'modules/faq';
@import 'modules/mobile-menu';
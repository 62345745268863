.signup-form {
    margin:0 auto;
    box-shadow: 10px 10px 30px 0 rgba(0,0,0,0.05);
}
.form-left {
    background-color:$darkPurple;
    padding:48px 112px 48px 48px;
    .reg-copy {
        margin-bottom:12px;
    }
    @include below($s) {
        display:none;
    }
}
.form-right {
    padding:48px;
    @include below($s) {
        padding:24px;
    }
}
fieldset {
    border:0;
}

label, .gfield_label {
    font-size: 12px;
    color: $darkPurple;
    letter-spacing: 0;
    display:block;
    margin-bottom:6px;
}
.input-wrap, .gfield {
    width:100%;
    display:block;
    margin-bottom:24px;
}


input[type="text"], select, input[type="email"], .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
    appearance:none;
    background: #FFFFFF;
    border: 1px solid #F6F4F4;
    padding:18px 0 20px 24px;
    width:100%;
    border-radius:0;
    transition:border-color .22s;
    &:focus {
        border-color:$darkPurple;
        outline:none;
    }
    &.footer-input {
        width:100%;
        background: $darkPurple;
        border: 1px solid #FFFFFF;
        position:relative;
        z-index:1;
        color:white;
        background-position:10% center;
        &:focus {
            border-color:#FFFFFF;
        }
    }
    &.invalid {
        border-color:red;
    }
}
.loading-box {
    opacity:0;
    pointer-events:none;
    position:absolute;
    width:100%;
    height:100%;
    background-image:$paleGradient;
    top:0;
    left:0;
    right:0;
    .reg-title {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
    }
    &.visible {
        opacity:1;
        pointer-events:initial;
        z-index:99;
    }
}
.js-form-done {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:99;
    width:65%;
    text-align:center;
}
select {
    cursor:pointer;
    background-repeat:no-repeat;
    background-image:url('../img/arrow.svg');
    background-position:95% center;
}

ul.gform_fields {
    list-style: none;
}
body {
    font-family:$mont;
	overflow-x:hidden;
	background: $darkWhite;
    * {
        box-sizing:border-box;
        -webkit-font-smoothing:antialiased;
		backface-visibility: hidden;
    }

}
.main {
    opacity:0;
    transition:1.2s opacity;
	width:100%;
	max-width:$desktopMaxWidth;
	margin:0 auto;
	background:#FFF;
	overflow:hidden;
}

.text-block {
	@include below($s) {
		padding:24px;
	}
}
.visible {
    
    opacity:1 !important;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/mont-light.woff') format('woff');
	font-weight:  300;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/mont-reg.woff') format('woff');
	font-weight:  400;
	font-style:   normal;
	font-stretch: normal;
}

strong {
	font-weight:400;
}
.floating-rect {
    position:absolute;
    top:-5%;
    z-index:0;
    &.left {
        left:-10%;
    }
    &.right-bottom {
        top:auto;
        bottom:-50%;
        right:-20%;
        &.small {
            right:-12%;
            bottom:-10%;
            transform:rotate(0deg);
        }
    }
    &.right-bottom-global {
        top:auto;
        bottom:0%;
        right:-20%;
        transform:rotate(90deg);
    }
    &.left-bottom {
        top:auto;
        bottom:-50%;
        left:-20%;
        right:auto;
        &.small {
            left:-12%;
            bottom:-10%;
            transform:rotate(-35deg);
        }
    }
    &.left-top {
        top:-10%;
        left:-20%;
    }
    &.right-top {
        top:10%;
        right:-30%;
        z-index:0;
    }
    @include below($m) {
        display:none;
    }
}